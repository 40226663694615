import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

const SubMapComponent = ({
  defaultCenter,
  position,
  defaultZoom,
  onClick,
  title,
  onLoad,
}) => {
  const handleMapClick = event => {
    const { latLng } = event.detail;
    if (latLng) {
      const latLngObj = {
        lat: latLng.lat,
        lng: latLng.lng,
      };
      onClick(latLngObj);
    }
  };

  const handleTilesLoaded = map => {
    console.log('Tiles loaded');
  };

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
      <Map
        style={{ width: '100%', height: '100%', borderRadius: '4px' }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom || 10}
        gestureHandling={'greedy'}
        options={{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: true,
          rotateControl: true,
          fullscreenControl: true,
        }}
        onClick={handleMapClick}
        onTilesLoaded={handleTilesLoaded}
      >
        {position && <Marker position={position} title={title} />}
      </Map>
    </APIProvider>
  );
};

export default SubMapComponent;
