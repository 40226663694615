import { useState, useEffect } from 'react';
import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Toolbar,
  Box,
  AppBar,
  useMediaQuery,
  Typography,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Badge,
  MenuItem,
  Menu,
  Divider,
} from '@mui/material';
import BellIcon from '@mui/icons-material/Notifications';
import { styled } from '@mui/material/styles';
import { SideBar } from 'components/common/SideBar/SideBar';
import logo1 from 'assets/img/logoHead.png';
import logo2 from 'assets/img/titleLogo.png';
import {
  selectUser,
  selectUserLocation,
  selectUserNotify,
} from 'redux/auth/authSlice';
import { SwitchLanguage } from 'components/common/SwitchLanguage/SwitchLanguage';
import json2mq from 'json2mq';
import { Container } from 'layouts/Container/Container';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import appbar from 'assets/img/appbar.webp';
import './MainAppBar.style.css';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SignUpButtons } from '../SignUpMenu/SignUpButtons';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  fetchUserLocationByIP,
  getUserLocation,
} from 'services/getUserLocation';
import {
  getFirestore,
  collection,
  query,
  getDocs,
  where,
} from 'firebase/firestore';
import { reportDateTime } from 'utils/constants';

const noLoggerAppBarStyle = {
  position: 'relative',
  height: '470px',
  '@media screen and (minWidth: 600px)': {
    minHeight: '420px',
  },
  backgroundImage: `url(${appbar})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundBlendMode: 'soft-light',
};

const loggerAppBarStyle = {
  position: 'relative',
  zIndex: 999,
  background: '#101010',
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export const MainAppBar = () => {
  const user = useSelector(selectUser);
  const isUserLocatinPermission = useSelector(selectUserLocation);
  const receivingNotification = useSelector(selectUserNotify);
  const { t } = useTranslation(['ui']);
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const matches = useMediaQuery(json2mq({ minWidth: 811 }));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [, setUserLocation] = useState(null);
  const [nearbyReports, setNearbyReports] = useState([]);
  const [reportsCount, setReportsCount] = useState(0);
  const [displayedRows] = useState(3);
  const [displayAllReports, setDisplayAllReports] = useState(false);

  const handleViewAll = () => setDisplayAllReports(!displayAllReports);
  useEffect(() => {
    if (receivingNotification) {
      async function fetchReports() {
        try {
          const location = isUserLocatinPermission
            ? await getUserLocation()
            : await fetchUserLocationByIP();
          console.log('Location data:', location);

          // Ensure location is valid before proceeding
          if (location && location.lat && location.lng) {
            setUserLocation(location);
            const reports = await getNearbyReports(
              location.lat,
              location.lng,
              500
            ); // 500 meters
            setNearbyReports(reports);
            setReportsCount(reports.length);
            localStorage.setItem('nearbyReports', JSON.stringify(reports));
          } else {
            console.error('Invalid location data:', location);
          }
        } catch (error) {
          console.error('Error fetching reports:', error);
        }
      }

      fetchReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const R = 6371;

  function haversineDistance(coords1, coords2) {
    const toRad = angle => (angle * Math.PI) / 180;

    const dLat = toRad(coords2.latitude - coords1.latitude);
    const dLng = toRad(coords2.longitude - coords1.longitude);

    const lat1 = toRad(coords1.latitude);
    const lat2 = toRad(coords2.latitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c * 1000; // Returns distance in meters
  }

  function getLatLngBounds(latitude, longitude, radiusInMeters) {
    const radiusInKm = radiusInMeters / 1000;

    const latDelta = (radiusInKm / R) * (180 / Math.PI);
    const lngDelta =
      (radiusInKm / (R * Math.cos((Math.PI * latitude) / 180))) *
      (180 / Math.PI);

    return {
      minLat: latitude - latDelta,
      maxLat: latitude + latDelta,
      minLng: longitude - lngDelta,
      maxLng: longitude + lngDelta,
    };
  }

  async function getNearbyReports(userLatitude, userLongitude, radius = 500) {
    const firestore = getFirestore();
    const reportsRef = collection(firestore, 'Reports');

    // Get the bounding box coordinates
    const { minLat, maxLat, minLng, maxLng } = getLatLngBounds(
      userLatitude,
      userLongitude,
      radius
    );

    // Step 1: Query for documents within the latitude range
    const latitudeQuery = query(
      reportsRef,
      where('reportType', '==', 'resourceScarcity'),
      where('latitude', '>=', minLat),
      where('latitude', '<=', maxLat)
    );

    const latitudeSnapshot = await getDocs(latitudeQuery);
    let reports = [];

    // Step 2: Filter results based on longitude, distance, and whether the user has already responded
    for (const doc of latitudeSnapshot.docs) {
      const data = doc.data();
      const reportLat = data.latitude;
      const reportLng = data.longitude;

      // Check if longitude is within the range
      if (reportLng >= minLng && reportLng <= maxLng) {
        // Calculate distance and check if within the radius
        const distance = haversineDistance(
          { latitude: userLatitude, longitude: userLongitude },
          { latitude: reportLat, longitude: reportLng }
        );

        if (distance <= radius) {
          // Step 3: Check if the user has already responded to this report
          const hazardUpdateRef = collection(
            firestore,
            `Reports/${doc.id}/HazardUpdate`
          );
          const hazardUpdateQuery = query(
            hazardUpdateRef,
            where('submittedUserId', '==', user)
          );
          const hazardUpdateSnapshot = await getDocs(hazardUpdateQuery);

          // If no documents found in HazardUpdate with matching submittedUserId, include the report
          if (hazardUpdateSnapshot.empty) {
            reports.push(data);
          }
        }
      }
    }

    // Step 4: Sort by timeObserved and limit to 5
    reports.sort(
      (a, b) => b.timeObserved.toMillis() - a.timeObserved.toMillis()
    );

    if (reports.length > 5) {
      reports = reports.slice(0, 5);
    }

    return reports;
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScrollToMap = () => {
    const mapElement = document.getElementById('map');

    mapElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const [isNavButtonsOpen, setNavButtonsOpen] = useState(false);
  const [, setArrowButtonClicked] = useState(false);

  const toggleNavButtons = () => {
    setNavButtonsOpen(!isNavButtonsOpen);
  };

  const handleArrowButtonClick = () => {
    toggleNavButtons();
    setArrowButtonClicked(true);
  };

  return (
    <AppBar style={user ? loggerAppBarStyle : noLoggerAppBarStyle}>
      <Container>
        <Toolbar
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 0 !important',
          }}
        >
          <Box
            sx={{
              width: '100%',
              alignItems: user ? 'center' : 'flex-start',
              justifyContent: user ? 'space-between' : 'flex-end',
            }}
          >
            {user ? (
              <Box>
                <SideBar />
                <Link to="/">
                  <Box>
                    <img
                      alt="logo"
                      src={logo1}
                      style={{ height: '40px', width: '52px' }}
                    />
                    {''}
                    {matches && (
                      <img
                        alt="logo2"
                        src={logo2}
                        style={{
                          paddingLeft: '8px',
                          width: '160px',
                          height: '18px',
                        }}
                      />
                    )}
                  </Box>
                </Link>
              </Box>
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  top: '55%',
                  left: '50%',
                  transform: 'translate(-50%, -45%)',
                  width: '100%',
                  maxWidth: '800px',
                  display: 'block',
                }}
              >
                <Box alignItems={'normal'}>
                  <Box sx={{ width: '35%' }}>
                    <Link to="/">
                      <img alt="logo" src={logo1} />
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      flexDirection: 'column',
                      width: '65%',
                      alignItems: 'flex-start',
                    }}
                  >
                    <img
                      alt="logo2"
                      src={logo2}
                      style={{
                        maxHeight: 60,
                        marginBottom: 24,
                      }}
                    />
                    <Box
                      sx={{
                        flexDirection: 'column',
                        alignItems: 'self-start',
                      }}
                    >
                      <Typography
                        variant="text"
                        sx={{
                          textTransform: 'uppercase',
                          whiteSpace: `${matches ? 'nowrap' : 'wrap'}`,
                          fontFamily: 'Montserrat',
                          fontSize: `${matches ? '24px' : '16px'}`,
                        }}
                      >
                        {t('appBar.desc1')}
                      </Typography>
                      <Typography
                        variant="text"
                        sx={{
                          textTransform: 'uppercase',
                          whiteSpace: `${matches ? 'nowrap' : 'wrap'}`,
                          fontFamily: 'Montserrat',
                          fontSize: `${matches ? '24px' : '16px'}`,
                        }}
                      >
                        {t('appBar.desc2')}
                      </Typography>
                      <Button
                        variant='contained'
                        children={
                          isHomePage
                            ? t('button.currentEvents')
                            : t('button.home')
                        }
                        onClick={
                          isHomePage ? handleScrollToMap : () => navigate('/')
                        }
                        style={{ marginTop: 24, alignItems: 'center' }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                justifyItems: 'center',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginLeft: 'auto',
                flexDirection: 'row',
              }}
            >
              {user && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 1,
                  }}
                >
                  {location.pathname !== '/' && (
                    <Link sx={{ color: '#B9B9B9' }} component={Link} to="/">
                      {matches ? (
                        <Button
                          aria-label="flex button group"
                        >
                          {t('topMenu.home')}
                        </Button>
                      ) : (
                        <Tooltip title={t('button.home')}>
                          <IconButton>
                            <HomeOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Link>
                  )}

                  {matches && (
                    <>
                      <Link
                        sx={{ color: '#B9B9B9' }}
                        component={Link}
                        to="/fullMap"
                      >
                        <Button
                          aria-label="flex button group"
                        >
                          {t('button.map')}
                        </Button>
                      </Link>

                      <Link to={`/report`}>
                        <Button
                          aria-label="flex button group"
                        >
                          {t('button.addNewReport')}
                        </Button>
                      </Link>
                    </>
                  )}
                </Box>
              )}
              <Box mr={1}>{!user && <SignUpButtons />}</Box>
              {user && receivingNotification && (
                <Box p={1}>
                  <IconButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    disabled={nearbyReports.length > 0 ? false : true}
                  >
                    <StyledBadge badgeContent={reportsCount} color="error">
                      <NotificationsIcon />
                    </StyledBadge>
                  </IconButton>
                  {anchorEl && nearbyReports && (
                    <>
                      {' '}
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        sx={{
                          '& .MuiAvatar-root': {
                            minWidth: 240,
                            padding: 2,
                            bgcolor: '#ffff',
                            backgroundImage: 'none',
                          },
                        }}
                        transformOrigin={{
                          horizontal: 'right',
                          vertical: 'top',
                        }}
                        anchorOrigin={{
                          horizontal: 'right',
                          vertical: 'bottom',
                        }}
                      >
                        <Box bgcolor={'#0000'} flexDirection={'column'}>
                          {nearbyReports
                            .slice(
                              0,
                              displayAllReports
                                ? nearbyReports.length
                                : displayedRows
                            )
                            .map(report => (
                              <Box key={report.reportId} p={1}>
                                <MenuItem
                                  key={report.reportId}
                                  component={Link}
                                  to={{
                                    pathname: '/account/notifications',
                                    state: { nearbyReports },
                                  }}
                                  onClick={() => {
                                    console.log('Navigating with state:', {
                                      nearbyReports,
                                    });
                                    handleClose();
                                  }}
                                >
                                  <Box
                                    flexDirection={'column'}
                                    alignItems={'normal'}
                                  >
                                    <Typography fontWeight={600}>
                                      {t('appBar.resourceNotify')}
                                    </Typography>
                                    <Typography>{t(`typeHazards.${report.reportType}.items.${report.hazardType}`)}</Typography>
                                    <Typography variant="caption">
                                      {reportDateTime(
                                        report.timeObserved.seconds * 1000
                                      )}
                                    </Typography>
                                  </Box>
                                </MenuItem>
                                <Divider orientation="horizontal" />
                              </Box>
                            ))}
                          {nearbyReports.length >= 5 && (
                            <Button
                              sx={{ display: 'block', marginLeft: 'auto' }}
                              onClick={handleViewAll}
                            >
                              {displayAllReports
                                ? t('pageReport.hide')
                                : t('pageReport.viewall')}
                            </Button>
                          )}
                        </Box>
                      </Menu>
                    </>
                  )}
                </Box>
              )}
            </Box>

            <Box
              sx={{
                textTransform: 'capitalize',
                fontSize: '14px',
              }}
              className="SwichLangBox"
            >
              <SwitchLanguage />
            </Box>
          </Box>
          {user && (
            <Box>
              {!matches && (
                <Accordion expanded={isNavButtonsOpen}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={handleArrowButtonClick}
                    sx={{
                      p: 0.5,
                      minHeight: '24px !important',
                      background: '#101010',
                      m: '0 !important',
                    }}
                  />
                  <AccordionDetails
                    sx={{ width: '100%', p: 0, background: '#101010' }}
                  >
                    {isNavButtonsOpen && (
                      <>
                        <Link
                          sx={{ color: '#B9B9B9' }}
                          component={Link}
                          to="/fullMap"
                        >
                          <Button
                            aria-label="flex button group"
                            sx={{
                              textTransform: 'capitalize',
                              mr: 1,
                            }}
                          >
                            {t('button.map')}
                          </Button>
                        </Link>

                        <Link to={`/report`}>
                          <Button
                            aria-label="flex button group"
                            sx={{
                              textTransform: 'capitalize',
                              
                              mr: 1,
                            }}
                          >
                            {t('button.addNewReport')}
                          </Button>
                        </Link>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          )}
          {!user && isHomePage && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                bgcolor: '#000000',
                width: '100%',
                paddingY: 2,
                borderRadius: '4px 4px 0 0',
              }}
            >
              <img
                alt="logo2"
                src={logo2}
                style={{
                  paddingLeft: '32px',
                  width: '288px',
                  height: '32px',
                }}
              />{' '}
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
