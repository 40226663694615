import React from 'react';
import { Box } from '@mui/material';
import { NewsFeed } from './NewsFeed';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

export const SocialMedia = ({ trendingCountry }) => {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = React.useState(0);

  return (
    <Box sx={{ width: '100%', display: 'inline' }}>
      <Box sx={{ width: '100%', height: '776px', bgcolor: '#1D1D1D' }}>
        <CustomTabPanel value={value} index={0}>
          <NewsFeed trendingCountry={trendingCountry} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};
