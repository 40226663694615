import { Modal, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './AlertModal.styled.css';
import 'utils/i18next';
import { MainButton, SecondMainButton } from 'components/ui/Button/Button';

export function AlertModal({
  open,
  handleClose,
  text,
  handleConfirm,
  children,
  buttonText,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="AlertWrapper">
          <Typography align={'center'}>{text}</Typography>
          {children}
          <div className="ButtonWrapper">
            <MainButton
              children={buttonText ? buttonText : t('button.yes')}
              onClick={handleConfirm}
            />
            {handleClose ? (
              <SecondMainButton
                children={t('button.no')}
                onClick={handleClose}
              />
            ) : (
              <></>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}
