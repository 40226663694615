import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  useTheme,
  Grid,
} from '@mui/material';
import { Container } from 'layouts/Container/Container';
import { useTranslation } from 'react-i18next';
//import { endpoints } from 'utils/constants';
import { typeHazards } from 'utils/type';
import { setSelectedEndpoint } from 'redux/report/reportSlice';
import { UserReports } from 'components/common/UserReports/UserReports';
import { UserSubmissions } from 'components/UserAccount/UserReports/UserSubmissions';

const ChooseReport = () => {
  const { t } = useTranslation(['ui', 'reports', 'countries', 'hazards']);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const dispatch = useDispatch();

  const handleSelectReport = (id, name) => {
    dispatch(setSelectedEndpoint({ id, name }));
  };

  return (
    <Container component="main" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Grid container spacing={4} justifyContent="center" sx={{ width: '100%' }}>
        
        {/* Main Section with Titles and Table */}
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              backgroundColor: isDarkMode ? theme.palette.grey[900] : theme.palette.grey[300],
              padding: '16px',
              borderRadius: '4px',
              mb: 3,
              width: '100%',
            }}
          >
            {/* Small Title */}
            <Typography variant="overline" color="textSecondary" textTransform="uppercase">
              {t('reportForm.createTitle',{ns:"reports"})}
            </Typography>
            
            
            <Typography variant="h1" color={isDarkMode ? 'primary' : 'textPrimary'} textTransform="uppercase" gutterBottom>
              {t('createReport.createReportTitle', {ns:"reports"})}
            </Typography>

            {/* Reports Table */}
            <TableContainer component={Paper} sx={{ borderRadius: '4px', overflow: 'hidden', width: '100%', backgroundColor: isDarkMode ? 'rgba(45, 45, 45, 0.9)' : 'rgba(255, 255, 255, 1)' }}>
              <Table>
                <TableBody>
                  {typeHazards
                    .filter(({ id }) => id !== 'nasa' 
                      && id !=='warCrime' 
                      && id !=='uxo'
                      && id !=='militaryActivity'
                      && id !=='terroristActivity'
                      && id !=='humanTrafficking'
                      && id !=='cyberThreats'      
                    )
                    .map(({ id, name, description }, index) => (
                      <TableRow
                        key={name}
                        sx={{
                          backgroundColor: index % 2 === 0 ? (isDarkMode ? 'rgba(55, 55, 55, 0.9)' : 'rgba(245, 245, 245, 1)') : (isDarkMode ? 'rgba(45, 45, 45, 1)' : 'rgba(255, 255, 255, 1)'),
                          '&:hover': {
                            backgroundColor: isDarkMode ? 'rgba(75, 75, 75, 0.8)' : 'rgba(0, 0, 0, 0.1)',
                          },
                        }}
                      >
                        {/* Select Button */}
                        <TableCell sx={{ border: 'none', padding: '12px' }}>
                          <Link to={`/report/${id}`} style={{ textDecoration: 'none' }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => handleSelectReport(id, name)}
                              aria-label={t(`pageReport.select`)}
                            >
                              {t('button.addNewReport')}
                            </Button>
                          </Link>
                        </TableCell>

                        {/* Report Name */}
                        <TableCell sx={{ border: 'none', padding: '8px', whiteSpace: 'nowrap' }}>
                          <Typography variant="body1" color={isDarkMode ? 'textPrimary' : 'textSecondary'}>
                            {t(name)}
                          </Typography>
                        </TableCell>

                        {/* Report Description */}
                        <TableCell sx={{ border: 'none', padding: '8px', whiteSpace: 'normal' }}>
                          <Typography variant="body1" color={isDarkMode ? 'textSecondary' : 'textPrimary'}>
                            {t(`${description}`)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

        {/* Side Bar with Advertisement and User Reports */}
        <Grid item xs={12} md={4}>
          <Box>
            <UserReports />
          </Box>
          <Box>
            <UserSubmissions />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChooseReport;
