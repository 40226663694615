import * as React from 'react';

//// User Contributed Reports List -- different than on choose reports page
import { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  getDocs,
  updateDoc,
  doc,
  query,
  where,
} from 'firebase/firestore';
import { selectUser } from 'redux/auth/authSlice';
import { setSelectedReport } from 'redux/report/updateReportSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Box,
  Modal,
  Table,
  TableRow,
  TableCell,
  IconButton,
  Button,
  useMediaQuery,
  Divider,
  TableBody,
  TableContainer,
  TableHead,
  Paper
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import VerifiedIcon from '@mui/icons-material/CheckCircle';
import RejectedIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/PendingActions';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import json2mq from 'json2mq';
import './UserReports.styled.css';

export const UserSubmissions = () => {
  const { t } = useTranslation();
  const [endpointData, setEndpointData] = useState([]);
  const db = getFirestore();
  const userId = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [selectedOldReport, setSelectedOldReport] = useState(null);
  const [selectedReportToDelete, setSelectedReportToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayedReports, setDisplayedReports] = useState([]);
  const [displayAllNewReports, setDisplayAllNewReports] = useState(false);
  const [displayAllReports, setDisplayAllReports] = useState(false);
  const [rejectedReports, setRejectedReports] = useState([]);
  const [displayAllRejectedReports, setDisplayAllRejectedReports] =
    useState(false);
  const [displayedRows] = useState(3);

  const matches = useMediaQuery(json2mq({ minWidth: 500 }));
  const matchesTab = useMediaQuery(json2mq({ minWidth: 1440 }));

  useEffect(() => {
    const reportsRef = collection(db, 'Reports');
    const reportsQuery = query(
      reportsRef,
      where('originalReportUserId', '==', userId)
    );
    console.log("Your User ID:",userId)

    getDocs(reportsQuery).then(querySnapshot => {
      const data = [];
      querySnapshot.forEach(doc => {
        data.push({ id: doc.id, ...doc.data() });
      });
      console.log("Data Fetched: ", data);
      setEndpointData(data);
    });
  }, [db, userId]);

  useEffect(() => {
    if (endpointData.length === 0) return;

    const displayed = endpointData.filter(report => !report.archived);
    const rejected = endpointData.filter(report => report.archived);

    setDisplayedReports(displayed);
    setRejectedReports(rejected);
  }, [endpointData]);


  const handleEditClick = report => {
    dispatch(setSelectedReport(report));
    navigate('/edit-report');
  };

  const handleViewDetailsClick = report => {
    dispatch(setSelectedReport(report));
    navigate(`/report/view/${report.reportId}`);
  };

  const handleDeleteClick = report => {
    setSelectedReportToDelete(report);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedReportToDelete) return;

    const reportRef = doc(db, 'Reports', selectedReportToDelete.id);

    try {
      await updateDoc(reportRef, {
        archived: true,
      });

      setIsModalOpen(false);
      setSelectedReportToDelete(null);
      setDisplayedReports(prevReports =>
        prevReports.filter(report => report.id !== selectedReportToDelete.id)
      );
    } catch (error) {
      console.error('Error updating archived field:', error);
    }
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
    setSelectedReportToDelete(null);
  };

  const recentReports = displayedReports.filter(
    report => !report.closedForUpdates && report.verificationStatus === "not started"
  );
  const olderReports = displayedReports.filter(
    report => report.verificationStatus === "verified"
  );

  const handleViewAllNew = () => setDisplayAllNewReports(!displayAllNewReports);
  const handleViewAll = () => setDisplayAllReports(!displayAllReports);
  const handleViewAllRejected = () =>
    setDisplayAllRejectedReports(!displayAllRejectedReports);



  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 150 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Report</TableCell>
            <TableCell>Date Added</TableCell>
            <TableCell align="right">Verified Status</TableCell>
            <TableCell align="right">Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recentReports
            .slice(
              0,
              displayAllNewReports ? recentReports.length : displayedRows
            )
            .sort((a, b) => b.timeObserved - a.timeObserved)
            .map(report => (
              <TableRow key={report.id}>
                <TableCell>
                  <Typography variant="body1">
                    {t(`${report.reportType}`)}:<br />
                    <Typography variant="caption">
                      {t(`${report.hazardType}`)}
                    </Typography>
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography>
                    {`${new Date(
                      Number(report.timeObserved.seconds * 1000)
                    ).toLocaleDateString()} `}
                  </Typography>
                </TableCell>
                {matchesTab && (
                  <TableCell>
                    <Typography>{t(`${report.country}`)}</Typography>{' '}
                  </TableCell>
                )}
                {matches && (
                  <TableCell>
                    {report.verificationStatus === 'not started' ? (
                      <Typography
                        color="secondary"
                        children={
                          matchesTab ? t('report.notVerified') : <VerifiedIcon />
                        }
                      />
                    ) : (
                      <Typography color="green" children={t('report.verified')} />
                    )}
                  </TableCell>
                )}
                <TableCell>
                  {matches ? (
                    <Button
                      variant="outlined"
                      children={t('button.edit')}
                      onClick={() => handleEditClick(report)}
                    />
                  ) : (
                    <IconButton onClick={() => handleEditClick(report)}>
                      <OpenInFullIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
