import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import MapComponent from './MapComponent';
import { Link } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './Map.style.css';

const Map = ({
  center,
  setEndpointChecks,
  endpointChecks,
  endpointData,
  endpoints,
}) => {
  const { t } = useTranslation('ui');

  return (
    <div
      style={{ width: '100%', backgroundColor: '#1D1D1D', borderRadius: '4px' }}
    >
      <Box className="mapMapNavBox" p={2} justifyContent={'space-between'}>
        <Typography variant="h2">{t('mainPage.recentActivityTitle')}</Typography>

        <Link to={`/fullMap`}>
          <Button variant="contained" sx={{ marginLeft: '16px' }}>
            <LocationOnIcon />
            <Typography
              sx={{
                ml: 1,
                textTransform: 'capitalize !important',
                color: 'inherit',
              }}
            >
              {t('button.viewFullMap')}
            </Typography>
          </Button>
        </Link>
      </Box>

      <Box className="mapMapWrapper">
        <MapComponent
          endpointChecks={endpointChecks}
          endpointData={endpointData}
          endpoints={endpoints}
          showRecentReports={true}
          center={center}
        />
      </Box>
    </div>
  );
};
export default Map;
